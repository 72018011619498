import '../styles/index.scss';
import $ from "jquery";
import 'selectric';
import 'magnific-popup';
import Inputmask from "inputmask";
import ls from 'local-storage';
import _ from 'lodash';
import config from './config';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const cakereqid = urlParams.get('cake');

const pubid = urlParams.get('publisher_id');

if (pubid == '1260') {
    var pixel_1260 = '<img src="https://www.facebook.com/tr?id=1062559747516093&ev=PageView&noscript=1" />';
    $('body').append(pixel_1260);
}
// CONFIGS
// var post_url = 'https://www.reallygreatrate.com/formdov.php';
// var post_url = '//' + config.endpoint + '/api/solar/';
var protocol = document.location.protocol;
var post_url = protocol + '//' + config.endpoint + '/formdov.php';

// If user leaves mid page
window.onbeforeunload = function () {
    // removes the function attachment and reverts to default behavior
    window.onbeforeunload = null;
    setTimeout(function () {
        setTimeout(function () {
            showPopUp();
            $('.popup')
                .removeClass('hide');
            $('.step.active')
                .removeClass('active');
            $('.step.step-confirmation')
                .addClass('active');
            $('.form-top .steps')
                .empty();
            var exit_strategy = true;

            getClickWall(exit_strategy);
            $('.step-form-wrap')
                .addClass('confirmation-step');
        }, 1500);
    }, 1);
    return 'Would you like to see rates from our top lenders?';
};


/*
   HELPERS
 */
/*
 * Basic email validation
 */
function validEmail(email) {
    var pattern = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return $.trim(email)
        .match(pattern) ? true : false;
}
/*
 * Basic zipcode validation
 */
function isValidZipCode(zip) {
    return /^\d{5}(-\d{4})?$/.test(zip);
}

/*
 * Sets hash value
 */
function setHash(hash) {
    // if(history.pushState) {
    //     history.pushState(null, null, hash);
    // }
    // else {
    //     location.hash = hash;
    // }
}
/*
 * Checks if value in array
 */
function isInArray(value, array) {
    return array.indexOf(value) > -1;
}

function RGRCallBack(EventID, Count)
{
    if (EventID == null) {
        EventID = "A1B2C3";
    }
    if (Count == null) {
        Count = 28;
    }


    $.ajax({
        async: false,
        
        url: "//www.reallygreatrate.com/api/mao_log/",
        type: "GET",
        dataType: 'json',
        data: { 
            'EventID': EventID,
            'Count': Count
        },
        
                
    });
}

var get_params = function (search_string) {
    var parse = function (params, pairs) {
        var pair = pairs[0];
        var parts = pair.split('=');
        var key = decodeURIComponent(parts[0]);
        var value = decodeURIComponent(parts.slice(1)
            .join('='));

        // Handle multiple parameters of the same name
        if (typeof params[key] === "undefined") {
            params[key] = value;
        } else {
            params[key] = [].concat(params[key], value);
        }

        return pairs.length == 1 ? params : parse(params, pairs.slice(1));
    };

    // Get rid of leading ?
    return search_string.length == 0 ? {} : parse({}, search_string.substr(1)
        .split('&'));
};
/*
 USER IP
 */
$.ajax({
    url: '//www.reallygreatrate.com/api/user/ip_address/',
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('#user_ip')
            .val(response.ip_address);
    }
});
/*
   MAGNIFIC POPUP
 */
$('.mfp-iframe')
    .each(function () { // the containers for all your galleries
        $(this)
            .magnificPopup({
                type: 'iframe',
                mainClass: 'iframe-popup'
            });
    });

$('.mfp-inline')
    .each(function () { // the containers for all your galleries
        $('.mfp-inline')
            .magnificPopup({
                type: 'inline'
            });
    });

/*
    POPUP
*/
function showPopUp() {
    setTimeout(function () {
        $('.step-form .step.active .radio-button a')
            .first()
            .focus();
    }, 301);
    $('html')
        .addClass('popup-open');
    $('.popup')
        .fadeIn();
    $(window)
        .scrollTop(0);
}

function myAdOptimizerTracker() {
    var clickwallPercentage;
    var value = window.location.hostname;
    $.ajax({
        async: false,

        url: '//www.reallygreatrate.com/api/clickwall_percentage/',
        type: "GET",
        dataType: 'json',
        data: { 'website': value },
        success: function (response) {
            clickwallPercentage = response;
        }
                
    });

}
    
function create_UUIDLanding(){
   var dt = new Date().getTime();
   var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = (dt + Math.random()*16)%16 | 0;
       dt = Math.floor(dt/16);
       return (c=='x' ? r :(r&0x3|0x8)).toString(16);
   });
   return uuid;
}

var LandingID = 14;
var LandingEventID = create_UUIDLanding();
function getClickWall(exit_strategy = false) {
    window.onerror = function (errorMsg, url, line) 
    {
       
       //Add logging logic here
       $.ajax({
           async: false,
           
           url: "//www.reallygreatrate.com/api/mao_errorlog/",
           type: "POST",
           dataType: 'json',
           data: { 
               'errorMsg': errorMsg,
               'url': url,
               'line': line
           },
                   
       });
    
       var MAOEndPointLanding = 'https://api.myadoptimizer.com/';
       var GlobalErrors = []; 
       var GlobalErrorRequest = {};
       GlobalErrorRequest.AdNetworkAPIID =  15;
       GlobalErrorRequest.ErrorStatusText = errorMsg + ' - ' + line;
       GlobalErrorRequest.ErrorTextStatus = 'Global Error ' + navigator.userAgent  + ' ' + window.location.origin + window.location.pathname; 
       GlobalErrorRequest.ErrorMessage = 'Global Error';
       GlobalErrorRequest.PayLoad = '';
       GlobalErrorRequest.APIURL  = url;
       GlobalErrorRequest.RequestDuration = 0;
       GlobalErrors.push(GlobalErrorRequest);
    
    
       var SaveURL  = MAOEndPointLanding + 'api/MAOSaveResults?';
       SaveURL += 'LandingPageID=' + LandingID;
       SaveURL += '&EventID=' + LandingEventID;
       SaveURL += '&RequestTypeID=' + 2;
    
       if (typeof jQuery == 'undefined') 
       {
           // Immediately-invoked function expression
           (function() {
               // Load the script
               var script = document.createElement("SCRIPT");
               script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js';
               script.type = 'text/javascript';
               script.onload = function() {
                  $(document).ready(function(){
                      $.ajax({
                               url: SaveURL,
                               dataType: 'json',
                               type: 'post',
                               contentType: 'application/json',
                               timeout: 3000,
                               data: JSON.stringify(GlobalErrors)     
                       });
                   });
               };
               document.getElementsByTagName("head")[0].appendChild(script);
           })();
        }
        else
        {
           $(document).ready(function(){
                       $.ajax({
                               url: SaveURL,
                               dataType: 'json',
                               type: 'post',
                               contentType: 'application/json',
                               timeout: 3000,
                               data: JSON.stringify(GlobalErrors)     
                       });
                   });
        }
    
    };
    
    if(exit_strategy == true) {
        $('#confirmation_qs')
            .show();
        $('#confirmation_qs_text')
            .empty()
            .text("Click on a lender below for your free quote!");
        $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/32d7a62d-ff40-42d0-ab9f-764aa1ad40ee.js'><\/script>");
    } else {
        $('#confirmation_qs')
            .show();
        $('body').append("<script src='https://cdn.myadoptimizer.com/maojsfiles/landingpagesjs/2f739c29-e650-4202-877a-f9624cb5bd9f.js'><\/script>");
        myAdOptimizerTracker();
    }
    
        var maoCashOut = $('input[name="cash_out"]').val();
        var maoZip = $('input[name="zip"]').val();
        var maoState = $('[name=state]').val();
        var maoCredit = $('[name="field_6"]:checked').val();
        var maoLoan = $('[name=field_4]').val();
        var maoHomeValue = $('[name=field_2]').val();
        var maoPropertyType = $('[name=field_1]:checked').val();
        var maoMilitary = $('[name="field_28"]:checked').val();
        var maoPub = $('[name=publisher_id]').val();
        var maoJornaya = $('[name=field_31]').val();
        var maoEmployed = $('[name=field_34]:checked').val();
    
        // MAO PII added  firstname lastname email phone address city 
    
        var maoFname = $('input[name="first_name"]').val();
        maoFname = encodeURIComponent(maoFname);
        var maoLname = $('input[name="last_name"]').val();
        maoLname = encodeURIComponent(maoLname);
        var maoEmail = $('input[name="email_address"]').val();
        var maoPhone = $('input[name="home_phone"]').val();
        maoPhone = encodeURIComponent(maoPhone);
        var maoCity = encodeURI(localStorage.getItem("city").replace(/['"]+/g, ''));
        var maoAddress = $('input[name="street_address"]').val();
        maoAddress = encodeURIComponent(maoAddress) + "," + encodeURIComponent(" " + localStorage.getItem("city").replace(/['"]+/g, '')) + "," + encodeURIComponent(" " + $('[name=state]').val() + " " + $('input[name="zip"]').val());
    
    
        if (maoMilitary == 'Y') {
            maoMilitary = 'Yes';
        }
    
        if (maoMilitary == 'N') {
            maoMilitary = 'No';
        }
    
        if (maoPropertyType == 'Condominium') {
            maoPropertyType = 'Condo%2FTownhome';
        } else if (maoPropertyType == 'Multi-Family Dwelling Unit') {
            maoPropertyType = 'Multi-Unit';
        } else if (maoPropertyType == 'Mobile Home') {
            maoPropertyType = 'Mobile';
        }
    
        if(maoCashOut > 0 ) {
            maoCashOut = "Yes";
        }
    
        if (exit_strategy == true) {
            var maoCashOut = '';
            var maoZip = '';
            var maoState = '';
            var maoCredit = '';
            var maoLoan = '';
            var maoHomeValue = '';
            var maoPropertyType = '';
            var maoMilitary = '';
            var maoEmployed = '';
    
            // MAO PII added 
    
            var maoFname = '';
            var maoLname = '';
            var maoEmail = '';
            var maoPhone = '';
            var maoAddress = '';
            var maoCity = '';
    
        }
        
    
        if (maoEmployed == "Other") {
            maoEmployed = "Not Employed";
        }
    
        var maoParams = 
            "?zipcode=" + maoZip + 
            "&SourceID=" + maoPub +
            "&State=" + maoState +
            "&creditscore=" + maoCredit +
            "&loanbalance=" + maoLoan +
            "&propertyvalue=" + maoHomeValue +
            "&PropertyType=" + maoPropertyType +
            "&MilitaryStatus=" + maoMilitary +
            "&ExtClickID=" + maoJornaya +
            "&CashOut=" + maoCashOut +
            "&MediaChannel=" + "" + 
            "&PropertyUse=" + "" + 
            "&EmploymentStatus=" + maoEmployed +
            "&FName=" + maoFname +
            "&LName=" + maoLname +
            "&Email=" + maoEmail +
            "&Phone=" + maoPhone + 
            "&Address=" + maoAddress +
            "&City=" + maoCity;
    
        window.history.replaceState(null, null, maoParams);
    
        var confirmation_div = $('#confirmation');
    
        confirmation_div.removeClass('collapse');
        confirmation_div.show();
    
}
function getOldClickWall(exit_strategy = false) {
    var confirmation_div = $('#confirmation');
    var affcid, key;


    // Generate Random Number to serve different click listings
    var randomized_num = getRandomNum(100); // random 1-100    
    // var renuantStates = ['ME', 'AK', 'MN', 'WA', 'UT', 'OR', 'MT', 'CT', 'MS', 'NH', 'NM'];
    if (exit_strategy == true) {
        var deleteCredit = "";
        $('[name=field_6]').val(deleteCredit);
    }
    var rt_data = {
        zip: $('input[name="zip"]')
            .val(),
        state: $('input[name="state"]')
            .val(),
        credit_status: $('input[name="field_6"]')
            .val(),
        loan_amt: $('input[name="field_3"]')
            .val(),
        military: $('input[name="field_28"]')
            .val(),
        property_value: $('input[name="field_2"]')
            .val(),
        property_type: $('input[name="field_1"]')
            .val(),
        publisher_id: $('input[name="publisher_id"]')
            .val(),
        subid: $('input[name="subid"]')
            .val()
    };

    var clickwallPercentage;

    $.ajax({
        async: false,
        url: "//www.reallygreatrate.com/api/clickwall_percentage/",
        type: "GET",
        dataType: 'json',
        success: function (response) {
            clickwallPercentage = response.number;
        }
                
    });
    
    //if number is below number or less serve clicks, otherwise serve renuant
    if (randomized_num <= clickwallPercentage) {
        /*
        CLICKS NET CLICK WALL
        */
        
        if (exit_strategy == true) {
            affcid = "1103401";
            key = "FEg9_CYDZWw1";
            $('#confirmation_qs_text')
                .empty()
                .text("Click on a lender below for your free quote!");

        } else {
            affcid = "1058706";
            key = "56pg95fEw2k1";
        }
        var zip = $('input[name="zip"]')
            .val();

        //     Parameter for Credit Rating
        var clicksnet_credit_rating = 'Good (620 - 719)';
        //     Parameter for Loan Amount
        var clicksnet_loan_range = '$150,001 - $160,000';
        //     Parameter for Loan Type and Term
        var clicksnet_loan_type_term = 'Refinance - FHA Rate - 30 Year';
        //     Subids are used to track conversions and traffic
        var subid1 = $('input[name="publisher_id"]')
            .val();
        //     Subids are used to track conversions and traffic
        var subid2 = $('input[name="rcid"]')
            .val();

        //Optional preview parameter
        // var creative_id = clicksNetGetQueryStringParam('preview');

        //Optional Query Parameters:
        //     showHeader=[true||false] -> will show or hide header (Default: true)
        //     showWidget=[true||false] -> will show or hide the subWidget filter bar (Default: true)
        //     showFooter=[true||false] -> will show or hide footer (Default: true)
        var showHeader = false;
        var showWidget = false;
        var showFooter = false;

        //baseQuery 
        //Purpose: base script query
        var baseQuery = clicksNetGetProtocol() + 'cdn.fcmrktplace.com/listing/?affcamid=' +
            affcid + '&key=' + key +
            '&subid1=' + subid1 + '&subid2=' + subid2;

        //clicksnet_script_src 
        //Purpose: script to fetch listings 
        //Filter Query Parameters:
        //     clicksnet_credit_rating: (eg.) Excellent (720 or Above), Good (620 - 719), Fair (580 - 619), Poor (579 or Below)
        //     clicksnet_loan_range: loan amount (eg.) $70,001 - $80,000
        //     clicksnet_loan_type_term: Loan Type + Rate Type (eg.) Purchase - FHA Rate - 30 Year
        var clicksnet_script_src = baseQuery + '&zip=' + zip +
            '&clicksnet_credit_rating=' + clicksnet_credit_rating + '&clicksnet_loan_range=' + clicksnet_loan_range +
            '&clicksnet_loan_type_term=' + clicksnet_loan_type_term + '&showHeader=' + showHeader +
            '&showWidget=' + showWidget + '&showFooter=' + showFooter;

        var fetchListings = function () {
            var clicksnet_script_src_obj = {
                baseQuery: baseQuery,
                zip: zip,
                clicksnet_credit_rating: clicksnet_credit_rating,
                clicksnet_loan_range: clicksnet_loan_range,
                clicksnet_loan_type_term: clicksnet_loan_type_term
            };

            var clicksnet_callback = function () {
                (clicksnet_listing.innerHTML = stripAndExecuteScript(
                    clicksnet_listing_content.content
                )),
                'undefined' != typeof clicksnet_sub_widget &&
                    clicksnet_sub_widget.init_clicksnet_sw(clicksnet_script_src_obj),
                    clicksNetAddExpandButtonListeners();
            };

            var clicksnet_listing_count = clicksnet_listing_count || 0;
            clicksnet_listing_count += 1;
            var clicksnet_listing_id = 'clicksnet_listing_' + clicksnet_listing_count;
            document.getElementById('confirmation_clicksnet')
                .innerHTML = '<div id="' + clicksnet_listing_id + '" style="margin-top: 25px;"></div>';
            var clicksnet_script = document.createElement('script');
            (clicksnet_script.type = 'text/javascript'),
            (clicksnet_script.async = !0),
            (clicksnet_script.src = clicksnet_script_src + '&gh=true&ghs=true'),
            clicksnet_script.addEventListener ?
                clicksnet_script.addEventListener('load', clicksnet_callback, !1) :
                clicksnet_script.readyState &&
                (clicksnet_script.onreadystatechange = function () {
                    ('loaded' == clicksnet_script.readyState ||
                        'complete' == clicksnet_script.readyState) &&
                    clicksnet_callback();
                });
            var clicksnet_listing = document.getElementById(clicksnet_listing_id);
            clicksnet_listing.appendChild(clicksnet_script);
        };
        $('#confirmationRT').hide();
        $('#confirmation_qs')
            .show();
        fetchListings();
        // if the randomized number is 91-100 show the renuant click wall
    } else {
        // State in renuantStates
        /*
        RENUANT CLICK WALL
         */
        
        $('#scrollbox')
            .remove();

        var mapPropertyType = function (type) {
            switch (type) {
            case "Single Family":
                return "1";
            case "Multi-Family Dwelling Unit":
                return "3";
            case "Condominium":
                return "2";
            case "Mobile Home":
                return "4";
            }
        };

        var mapCreditStatus = function (credit) {
            switch (credit) {
            case "Excellent":
                return "1";
            case "Good":
                return "3";
            case "Fair":
                return "4";
            case "Poor":
                return "5";
            case "":
                return "";
            }

        };

        var mapMilitary = function (military) {
            var mapped = military == 'Y' ? 1 : 2;
            return mapped;
        };

        var mapLoanAmount = function (loan) {
            switch (loan) {
            case "59900":
            case "75000":
            case "90000":
                return "2";
            case "105000":
            case "120000":
            case "135000":
            case "150000":
            default:
                return "3";
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
                return "11";
            case "555000":
            case "570000":
            case "585000":
                return "12";
            case "600000":
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
                return "15";
            case "750000":
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var mapPropertyValue = function (value) {
            switch (value) {
            case "90000":
                return "2";
            case "105000":
            default:
                return "3";
            case "120000":
            case "135000":
            case "150000":
            case "165000":
            case "180000":
            case "195000":
                return "4";
            case "210000":
            case "225000":
            case "240000":
                return "5";
            case "255000":
            case "270000":
            case "285000":
            case "300000":
                return "6";
            case "315000":
            case "330000":
            case "345000":
                return "7";
            case "360000":
            case "375000":
            case "390000":
                return "8";
            case "405000":
            case "420000":
            case "435000":
            case "450000":
                return "9";
            case "465000":
            case "480000":
            case "495000":
                return "10";
            case "510000":
            case "525000":
            case "540000":
            case "555000":
                return "11";
            case "570000":
            case "585000":
            case "600000":
                return "12";
            case "615000":
            case "630000":
            case "645000":
                return "13";
            case "660000":
            case "675000":
            case "690000":
                return "14";
            case "705000":
            case "720000":
            case "735000":
            case "750000":
                return "15";
            case "765000":
            case "780000":
            case "795000":
                return "16";
            case "810000":
            case "825000":
            case "840000":
                return "17";
            case "855000":
            case "870000":
            case "885000":
                return "18";
            case "900000":
            case "915000":
            case "930000":
            case "945000":
                return "19";
            case "960000":
            case "975000":
            case "990000":
            case "1000000":
                return "20";
            case "1100000":
            case "1200000":
            case "1300000":
            case "1400000":
            case "1500000":
            case "1600000":
            case "1700000":
            case "1800000":
            case "1900000":
            case "2000000":
            case "2000001":
                return "21";
            }
        };

        var renData = {
            state: rt_data.state,
            pubcampaignid: "5529",
            loantype: "refinance",
            propertytype: mapPropertyType(rt_data.property_type),
            PropertyUse: "1",
            creditscore: mapCreditStatus(rt_data.credit_status),
            militaryservice: mapMilitary(rt_data.military),
            foreclosure: "2",
            interestrate: "4.35",
            loanbalance: mapLoanAmount(rt_data.loan_amt),
            propertyvalue: mapPropertyValue(rt_data.property_value),
            mS1: "SLS",
            mS2: rt_data.publisher_id,
            mS3: rt_data.subid
        };
        // postscribe('#confirmation_sh', '<script src="https://api.renuant.com/search/api/Ads/Loader"></script>');
        // Renuant.Render(data);
        confirmation_div.removeClass('collapse');
        confirmation_div.show();
        var iframe = document.createElement("iframe"); // Create a <iframe> node
        iframe.setAttribute('id', 'renuant-conf');
        var rate_table_source = "https://api.renuant.com/search/blue/green/Render?state=" + renData.state + "&pubcampaignid=" + renData.pubcampaignid + "&loantype=" + renData.loantype + "&propertytype=" + renData.propertytype + "&PropertyUse=" + renData.PropertyUse + "&creditscore=" + renData.creditscore + "&militaryservice=" + renData.militaryservice + "&foreclosure=" + renData.foreclosure + "&interestrate=" + renData.interestrate + "&loanbalance=" + renData.loanbalance + "&propertyvalue=" + renData.propertyvalue + "&template_name=basic&mS1=" + renData.mS1 + "&mS2=" + renData.mS2 + "&mS3=" + renData.mS3;
        
        iframe.src = rate_table_source;
        iframe.style.cssText = "width:100%";
        iframe.frameborder = 0;
        iframe.scrolling = "no";
        document.getElementById("confirmationRT")
            .appendChild(iframe);
    }
}

function getRandomNum(num) {
    return Math.floor(Math.random() * Math.floor(num)) + 1;
}


function hidePopUp() {
    $('html')
        .removeClass('popup-open');
    $('.popup')
        .fadeOut();
}
/*
    Generate a TrustedForm Certificate
 */
$('input')
    .on('input', function () {
        this.setAttribute("value", $(this)
            .val());
    });

var field = 'xxTrustedFormCertUrl';
var provideReferrer = false;
var tf = document.createElement('script');
tf.type = 'text/javascript';
tf.async = true;
tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date()
    .getTime() + Math.random();
var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(tf, s);

/*
    GET Default params
 */
var params = get_params(location.search);
// publisher_id
if (typeof params.publisher_id !== "undefined") {
    $('input[name="publisher_id"]')
        .val(params.publisher_id);
} else {
    $('input[name="publisher_id"]')
        .val(632);
}
// subid
if (typeof params.subid !== "undefined") {
    $('input[name="subid"]')
        .val(params.subid);
} else {
    $('input[name="subid"]')
        .val('');
}
// rcid
if (typeof params.rcid !== "undefined") {
    $('input[name="rcid"]')
        .val(params.rcid);
} else {
    $('input[name="rcid"]')
        .val('');
}
// zip
if (typeof params.zip !== "undefined") {
    $('input[name="zip_code"]')
        .val(params.zip);
}else {
    $('input[name="zip_code"]')
        .val('');
}
// street address
if (typeof params.street_address !== "undefined") {
    $('input[name="street_address"]')
        .val(params.street_address.replaceAll('+', ' '));
}
// address
if (typeof params.address !== "undefined") {
    $('input[name="street_address"]')
        .val(params.address.replaceAll('+', ' '));
}
// first name
if (typeof params.firstname !== "undefined") {
    $('input[name="first_name"]')
        .val(params.firstname);
} else {
    $('input[name="first_name"]')
        .val('');
}
// last name
if (typeof params.lastname !== "undefined") {
    $('input[name="last_name"]')
        .val(params.lastname);
} else {
    $('input[name="last_name"]')
        .val('');
}
// email address
if (typeof params.email !== "undefined") {
    $('input[name="email_address"]')
        .val(params.email);
} else {
    $('input[name="email_address"]')
        .val('');
}
// home phone
if (typeof params.phone !== "undefined") {
    let pre_phone = params.phone;
    if (pre_phone.length < 10) {
        pre_phone = '';
    } else if (pre_phone > 10 && (pre_phone.startsWith('1') || pre_phone.startsWith('0'))) {
        pre_phone = pre_phone.substr(1);
    }
    $('input[name="home_phone"]')
        .val(pre_phone);
} else {
    $('input[name="home_phone"]')
        .val('');
}

/*
    HID
 */
$.ajax({
    url: '//' + config.endpoint + '/api/hdi/?upload_type=' + $('input[name="upload_type"]')
        .val() + '&publisher_id=' + $('input[name="publisher_id"]')
        .val(),
    dataType: 'json',
    method: 'get',
    data: {},
    success: function (response) {
        $('input[name="hid"]')
            .val(response.hid);
    }
});



/*
    ZIP CODE
 */
$('.start-form button')
    .click(function (e) {
        e.preventDefault();
        var $el = $(this)
            .prev('input');
        var $button = $(this);
        var value = $el.val();
        var $form = $el.parent('form');
        $form.find('.error')
            .fadeOut();
        $el.removeClass('input-error');

        if (!$button.is(":disabled")) {
            $button.prop('disabled', true);
            if (value === "" || !isValidZipCode(value)) {
                $el.addClass('input-error');
                $form.find('.error')
                    .text('Please enter a valid ZIP code')
                    .fadeIn();
            } else {
                getCity(value);
            }
            $button.prop('disabled', false);
        }
    });

/*
 * Get city name
 */
var zip_response = null;

function getCity(value) {
    // Check zip code
    $.ajax({
        url: '//www.reallygreatrate.com/api/zipcode_lookup/?zip=' + value,
        dataType: 'json',
        method: 'get',
        data: { 'zip': value },
        success: function (response) {
            zip_response = response;
            zip_response.zip = value;

            $('input[name="zip"]')
                .val(value);
            $('input[name="city"]')
                .val(response.city);
            $('input[name="state"]')
                .val(response.state);

            $('.street-info')
                .text(response.city + ', ' + response.state + ', ' + value);
            $('.city-title-popup')
                .text(response.city);

            ls('zip', value);
            ls('city', response.city);
            ls('state', response.state);

            if (response.city) {
                showPopUp();
            } else {
                $el.addClass('input-error');
                $form.find('.error')
                    .fadeIn();
            }
        }
    });
}

$(document)
    .on('change', '.radio-list:visible input', function () {
        $('.radio-list:visible li')
            .removeClass('active');
        $(this)
            .parent('label')
            .parent('li')
            .addClass('active');
    });

var step_2 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_1"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please Select Home Type</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_3 = {
    select: function () {
        // Init custom selects
        $('#property-value')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //    $('input[name="provider-radio"]').prop('checked', false);
                    //   $('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        //$(document).on('change', '#property-value', function () {
        //var val = $('#property-value option:selected').val();
        //$('input[name="property-value"]').prop('checked', false);
        //$('input[name="field_2"]').val(val);
        //});

        $('#mortgage-balance')
            .selectric({
                nativeOnMobile: true,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();
                    //if(el_text !== 'Other') {
                    //$('input[name="mortgage-balance"]').prop('checked', false);
                    //$('input[name="field_2"]').val(el_val);
                    //$('input[name="field_2"]').next('span').text(el_text);
                    //}
                }
            });
        $(document)
            .on('change', 'mortgage-balance', function () {
                //var val = $('#property-value option:selected').val();
                //$('input[name="mortgage-balance"]').prop('checked', false);
                //$('input[name="field_2"]').val(val);
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            var is_prop_selected = ($('#property-value option:selected')
                .length > 0);
            var is_mort_selected = ($('#mortgage-balance option:selected')
                .length > 0);
            if (!is_prop_selected && !is_mort_selected) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select estimated property value and estimated mortgage balance</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (!is_prop_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated property value</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (!is_mort_selected) {
                    $('.step-form .step.active .errors')
                        .html('<span class="error">Please select estimated mortgage balance</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
            }
            $button.prop('disabled', false);
            step_5.init();
        }
    }
};

step_3.select();

var step_4 = {
    validate: function () {

        var $button = $('.step-form .step.active button');

        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');

            if (!$('.step-form .step.active input[name="field_6"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select how you would rate your credit</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }

            $button.prop('disabled', false);
        }
    }
};

var step_5 = {
    init: function () {
        const propertyValue = $('#property-value').val();
        const loanAmount = $('#mortgage-balance').val();
        const ltvMax = propertyValue * 0.95 - loanAmount;

        let slider = document.getElementById("CashoutAmount");
        const errorMsg = document.getElementById('cashOutMsg');

        errorMsg.classList.add("slider__none");
        let output = document.getElementById("cashOutValue");
        const upperBound = document.getElementById('upperBound');
        const lowerBound = document.getElementById('lowerBound');

        slider.addEventListener('click', function() {
            if(ltvMax <= 0) {
                errorMsg.classList.remove("slider__none");
            }else {
                errorMsg.classList.add("slider__none");
            }
        });
        slider.addEventListener('touchstart', function() {
            if(ltvMax <= 0) {
                errorMsg.classList.remove("slider__none");
            }else {
                errorMsg.classList.add("slider__none");
            }
        });
        console.log(propertyValue + ' ' + loanAmount + ' ' + ltvMax);
        if(ltvMax <= 0) {
            slider.value = 0;
            slider.max = 0;
            slider.min = 0;
            slider.step = 0;

            upperBound.innerText = '$400k';
            output.innerText = '$0 (No Cash)';

        } else {
            slider.classList.remove("slider__none");
            errorMsg.classList.add("slider__none");
            slider.min = 0;
            slider.max = ltvMax - ltvMax%5000;
            slider.step = 5000;
    
            lowerBound.innerText = '$0';
            upperBound.innerText = '$' + ltvMax;
    
            let ltv80 = propertyValue * 0.8 - loanAmount;

            if(ltv80 % 5000 != 0) {
                ltv80 = ltv80 - (ltv80 % 5000);
            }
            slider.value = ltv80;
    
            if(ltv80 <= 0) {
                output.innerText = '$0 (No Cash)';
            }else {
                output.innerText = '$' + (ltv80-4999) + ' to  $' + ltv80;
            }
        }

        slider.oninput = function() {
            let lowerCashout = this.value >= 4999 ? this.value-4999:0;
            let upperCashout = this.value;
            if(this.value == 0) {
                output.innerText = '$0 (No Cash)';
            }else {
                if(this.value == (ltvMax - ltvMax%5000)) {
                    output.innerHTML = '$' + lowerCashout + ' to $' + ltvMax;
                }else {
                    output.innerHTML = '$' + lowerCashout + ' to $' + upperCashout;
                }
            }
        };
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // if (!$('.step-form .step.active input[name="cash_out"]:checked')
            //     .val()) {
            //     $('.step-form .step.active .errors')
            //         .html('<span class="error">Please select if you would like to take cash out</span>');
            //     $('.step-form .step.active')
            //         .addClass('has-errors');
            // }
            $button.prop('disabled', false);
            var slider = document.getElementById("CashoutAmount");
            const propertyValue = $('#property-value').val();
            const loanAmount = $('#mortgage-balance').val();
            const ltvMax = propertyValue * 0.95 - loanAmount;
            if(slider.value == 0) {
                $('input[name="cash_out"]').val('No');
                console.log($('input[name="cash_out"]').val());
            }else {
                if(slider.max == slider.value) {
                    $('input[name="cash_out"]').val(ltvMax);
                }else {
                    $('input[name="cash_out"]').val(slider.value);
                }
                console.log($('input[name="cash_out"]').val());
            }
        }
    }
};

step_5.init();

var step_6 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_34"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select your employment status</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_7 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_23"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if your mortgage is FHA</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_8 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            if (!$('.step-form .step.active input[name="field_28"]:checked')
                .val()) {
                $('.step-form .step.active .errors')
                    .html('<span class="error">Please select if you are a veteran or active military</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

var step_9 = {
    data: {
        dob: null,
        year: '00',
        month: '00',
        date: '00'
    },
    init: function () {
        let dateDropdown = document.getElementById('dob_year');
        dateDropdown.innerHTML = '';
        
        let currentYear = new Date().getFullYear() - 18;
        let earliestYear = new Date().getFullYear() - 100;
        let i = 1;
        
        while (currentYear >= earliestYear) {
            if (i == 1) {
                let textOption = document.createElement('option');
                textOption.text = "Select Year";
                textOption.value = "00";
                dateDropdown.add(textOption);
                i++;
            }
            let dateOption = document.createElement('option');
            dateOption.text = currentYear;
            dateOption.value = currentYear;
            dateDropdown.add(dateOption);
            currentYear -= 1;
            
        }
        dateDropdown.value = this.data.year;
        
        let currentMonth = 1;
        let currentDate = '';
        if (this.data.year != '00' && this.data.month != '00') {
            currentDate = new Date(this.data.year, this.data.month, 1);
        } else {
            currentDate = new Date(new Date().getFullYear(), currentMonth, 1);
        }
        
        let lastDayofMon = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
        let dayNum = lastDayofMon.getDate();
        dateDropdown = document.getElementById('dob_day');
        dateDropdown.innerHTML = '';
        i = 1;
        while (dayNum >= i) {
            if (i == 1) {
                let textOption = document.createElement('option');
                textOption.text = "Select Day";
                textOption.value = "00";
                dateDropdown.add(textOption);                
            }
            let dateOption = document.createElement('option');
            let text = '0'+i;
            dateOption.text = text.substring(text.length-2);
            dateOption.value = text.substring(text.length-2);
            dateDropdown.add(dateOption);
            i += 1;
        }     
        if (this.data.date > dayNum) {
            dateDropdown.value  = '00';
            this.data.date = '00';
        } else {
            dateDropdown.value = this.data.date;
        }
                  
    },
    select: function () {        
        $('#dob_day')
            .selectric({
                disableOnMobile: false,
                nativeOnMobile: false,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();                    
                    step_9.data.date = el_val;
                    $('#dob').val(step_9.data.year+'-'+step_9.data.month+'-'+step_9.data.date);
                }
            });
        
        $('#dob_month')
            .selectric({
                disableOnMobile: false,
                nativeOnMobile: false,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();      
                    step_9.data.month = el_val;      
                    step_9.init();
                    step_9.select();              
                    $('#dob').val(step_9.data.year+'-'+step_9.data.month+'-'+step_9.data.date);
                }
            });
        $('#dob_year')
            .selectric({
                disableOnMobile: false,
                nativeOnMobile: false,
                onChange: function (element) {
                    $('.select-wrap')
                        .addClass('changed');
                    var el_val = $(element)
                        .find('option:selected')
                        .val();
                    var el_text = $(element)
                        .find('option:selected')
                        .text();   
                    step_9.data.year = el_val;   
                    step_9.init();
                    step_9.select();                                                
                    $('#dob').val(step_9.data.year+'-'+step_9.data.month+'-'+step_9.data.date);
                }
            });
    },
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');            

            if (this.data.year == '00' || this.data.month == '00' || this.data.date == '00') {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please select Month, Date, and Year of your birth.</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } 
            $button.prop('disabled', false);
        }
    }
};
step_9.init();
step_9.select();

var step_10 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // First name
            var first_name = $('.step-form .step.active input[name="first_name"]')
                .val();
            first_name = $.trim(first_name);
            // Last name
            var last_name = $('.step-form .step.active input[name="last_name"]')
                .val();
            last_name = $.trim(last_name);
            // Email
            var email = $('.step-form .step.active input[name="email_address"]')
                .val();
            email = $.trim(email);

            if (first_name === "" && last_name === "" && email === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your First Name, Last Name and Email</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else {
                if (first_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your First Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (last_name === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Last Name</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                }
                if (email === "") {
                    $('.step-form .step.active .errors')
                        .append('<span class="error">Please fill in your Email Address</span>');
                    $('.step-form .step.active')
                        .addClass('has-errors');
                } else {
                    if (!validEmail(email)) {
                        $('.step-form .step.active .errors')
                            .append('<span class="error">Please check your Email Address formatting (email@domain.com)</span>');
                        $('.step-form .step.active')
                            .addClass('has-errors');
                    }
                }
            }
            $button.prop('disabled', false);
        }
    }
};

var step_11 = {
    validate: function () {
        var $button = $('.step-form .step.active button');
        if (!$button.is(":disabled")) {
            $('.step-form .step.active .errors')
                .empty();
            $button.prop('disabled', true);
            $('.step-form .step.active')
                .removeClass('has-errors');
            // Phone
            var phone = $('.step-form .step.active input[name="home_phone"]')
                .val();
            phone = $.trim(phone);
            phone = phone.replace(/[^0-9]/g, '');
            // Address
            var street = $('.step-form .step.active input[name="street_address"]')
                .val();
            street = $.trim(street);

            console.log('phone verification start');

            if (phone === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Phone Number</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            } else if (phone !== "" && phone.length < 10) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please check Phone Number formatting (9999999999)</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }

            if (phone.startsWith("1") == true || phone.startsWith("0") == true) {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please don\'t start Phone Number with 1 or 0</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }

            console.log('phone verification end');

            if (street === "") {
                $('.step-form .step.active .errors')
                    .append('<span class="error">Please fill in your Street Address</span>');
                $('.step-form .step.active')
                    .addClass('has-errors');
            }
            $button.prop('disabled', false);
        }
    }
};

function changeStep(step) {

    setHash('#' + step);
    $('.step-form .step')
        .removeClass('active');
    $('.step-form .step[data-step="' + step + '"]')
        .addClass('active');
    $('.form-top .steps .current')
        .text(step);
    //$('.step-form .step.active input').first().focus();
    switch (step) {
    case 3:
        //$('input[name="field_2"]').val($('input[name="provider-radio"]:checked').val());
        break;
    case 4:
        //set Loan Amount value to first mortgage balance value
        var f4value = $("[name = field_4]")
            .val();
        $("[name = field_3]")
            .val(f4value);
        break;
    case 6:
        // Add input mask
        var selector = document.getElementById("home_phone");
        var im = new Inputmask("9999999999");
        im.mask(selector);
        break;
    }
}

$(document)
    .on('click', '.step-back', function (e) {
        e.preventDefault();
        var step = $('.step-form .step.active')
            .data('step') - 1;
        if (step > 1) {
            changeStep(step);
        } else {
            // hide popup
            hidePopUp();
        }
    });

$(document)
    .on('submit', 'form[name="leadform"]', function (e) {
        e.preventDefault();
    });

$(document)
    .on('click', '.step-button:visible', function (e) {
        e.preventDefault();
        var step = $(this)
            .data('step');
        if (step === 'submit') {
            var anura_json = sessionStorage.getItem('anura_final_response');
            step_11.validate();
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                convert_fire = true;
                window._conv_q = window._conv_q = [];
                _conv_q.push(['recheck_goals']);

                //submit
                $('[name=anura]')
                .val(anura_json);
                $('[name=referrer]')
                    .val(document.referrer);
                $('[name=useragent]')
                    .val(window.navigator.userAgent);
                var formData = $('#leadform')
                    .serialize();
                // var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                // if(iOS == false) {
                //     if (typeof _webpushTrackAttributes == 'function') {                 

                //       _webpushTrackAttributes({
                //         "aff_id": $('[name=publisher_id]').val(),
                //         "military": $('[name=field_28]').val(),
                //         "first_name": $('[name=first_name]').val(),
                //         "last_name": $('[name=last_name]').val(),
                //         "state": $('[name=state]').val(),
                //         "zip_code": $('[name=zip_code]').val(),
                //         "home_type": $('[name=field_1]').val(),
                //         "property_value": $('[name=field_2]').val(),
                //         "mortgage_balance": $('[name=field_4]').val(),
                //         "cash_out": $('[name=cash_out]').val(),
                //         "credit_status": $('[name=field_6]').val()
                //     });
                //     } else {
                
                //     }
                // }
                // Ajax call to post data
                $.ajax({
                    url: post_url,
                    dataType: 'json',
                    method: 'post',
                    data: formData,
                    success: function (results) {                        
                        
                        if (results.status == 'success') {

                            if ($('input[name="publisher_id"]').val() == '897') {
                                var ltv_897 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                         
                                var loan_897 = $('select[name="field_4"]').val();
                                var credit_897 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_897 = ['Excellent', 'Good'];
                                var pub897BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_897 = $('input[name="field_1"]:checked').val();

                                if (ltv_897 <= 1 &&
                                    loan_897 >= 125000 &&
                                    tier1_credits_897.indexOf(credit_897) != -1 &&
                                    pub897BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm897 = document.createElement('iframe');
                                    ifrm897.setAttribute('scrolling', 'no');
                                    ifrm897.setAttribute('frameborder', '0');
                                    ifrm897.setAttribute('width', '1');
                                    ifrm897.setAttribute('height', '1');
                                    ifrm897.setAttribute('src', '//trackqualitylps.com/p.ashx?o=387&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm897); // to place at end of document
                                }
                            }

                             if ($('input[name="publisher_id"]').val() == '1018') {
                                var ltv_1018 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                   
                                var loan_1018 = $('select[name="field_4"]').val();
                                var credit_1018 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1018 = ['Excellent', 'Good'];
                                var pub1018BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1018 = $('input[name="field_1"]:checked').val();

                                if (ltv_1018 <= 0.95 &&
                                    loan_1018 >= 125000 &&
                                    tier1_credits_1018.indexOf(credit_1018) != -1 &&
                                    pub1018BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1018 = document.createElement('iframe');
                                    ifrm1018.setAttribute('scrolling', 'no');
                                    ifrm1018.setAttribute('frameborder', '0');
                                    ifrm1018.setAttribute('width', '1');
                                    ifrm1018.setAttribute('height', '1');
                                    ifrm1018.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=383&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1018); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1133') {
                                var ltv_1133 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                             
                                var loan_1133 = $('select[name="field_4"]').val();
                                var credit_1133 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1133 = ['Excellent', 'Good'];
                                var pub1133BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1133 = $('input[name="field_1"]:checked').val();

                                if (ltv_1133 <= 0.95 &&
                                    loan_1133 >= 125000 &&
                                    housetype_1133 != "Mobile Home" &&
                                    tier1_credits_1133.indexOf(credit_1133) != -1 &&
                                    pub1133BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1133 = document.createElement('iframe');
                                    ifrm1133.setAttribute('scrolling', 'no');
                                    ifrm1133.setAttribute('frameborder', '0');
                                    ifrm1133.setAttribute('width', '1');
                                    ifrm1133.setAttribute('height', '1');
                                    ifrm1133.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=382&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1133); // to place at end of document
                                }
                            }

                         

                            if ($('input[name="publisher_id"]').val() == '1161') {

                                var ltv_1161 = Math.round(($('select[name="field_4"]')
                                         .val() / $('select[name="field_2"]')
                                         .val()) * 100) / 100;                            
                                var house_1161 = $('select[name="field_2"]').val();
                                var loan_1161 = $('select[name="field_4"]').val();
                                var credit_1161 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1161 = ['Excellent', 'Good'];
                                var pub1161BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                var housetype_1161 = $('input[name="field_1"]:checked').val();

                                if (ltv_1161 <= .90 &&
                                    loan_1161 >= 150000 &&
                                    house_1161 >= 100000 &&
                                    housetype_1161 != "Mobile Home" &&
                                    tier1_credits_1161.indexOf(credit_1161) != -1 &&
                                    pub1161BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var subid = $('input[name="subid"]').val();
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1161 = '<img src="//www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1161);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1162') {
                                var ltv_1162 = Math.round(($('select[name="field_4"]')
                                   .val() / $('select[name="field_2"]')
                                   .val()) * 100) / 100;                            
                                var house_1162 = $('select[name="field_2"]').val();
                                var loan_1162 = $('select[name="field_4"]').val();
                                var credit_1162 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1162 = ['Excellent', 'Good', 'Fair'];
                                var pub1162BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                if (ltv_1162 <= .95 &&
                                    loan_1162 >= 125000 &&
                                    house_1162 >= 100000 &&
                                    tier1_credits_1162.indexOf(credit_1162) != -1 &&
                                    pub1162BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0) {
                                    var subid = $('input[name="subid"]').val();
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1162 = '<img src="//www.affiliatestrk.com/rd/apx.php?id=215&type=4&hid=' + subid + '&transid=' + lead_id + '" width="1" height="1" style="display:none;" />';
                                    $('body')
                                        .append(pixel_1162);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1254') {
                                var ltv_1254 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1254 = $('select[name="field_4"]').val();
                                var credit_1254 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1254 = ['Excellent', 'Good'];
                                var pub1254BadStates = ['AZ', 'OH', 'NY', 'MA'];
                             
                                // var housetype_1254 = $('input[name="field_1"]:checked').val();

                                if (ltv_1254 <= 0.95 &&
                                    loan_1254 >= 125000 &&
                                    // housetype_1254 != "Mobile Home" &&
                                    tier1_credits_1254.indexOf(credit_1254) != -1 &&
                                    pub1254BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                   var lead_id = results.pixel.ref_01;
                                    var ifrm1254 = document.createElement('iframe');
                                    ifrm1254.setAttribute('scrolling', 'no');
                                    ifrm1254.setAttribute('frameborder', '0');
                                    ifrm1254.setAttribute('width', '1');
                                    ifrm1254.setAttribute('height', '1');
                                    ifrm1254.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=376&e=ld&t=' + lead_id);
                                    document.body.appendChild(ifrm1254); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1255') {
                                var ltv_1255 = Math.round(($('select[name="field_4"]')
                                 .val() / $('select[name="field_2"]')
                                 .val()) * 100) / 100;
                                // var house_1255 = $('select[name="field_2"]')
                                //     .val();
                                var loan_1255 = $('select[name="field_4"]').val();
                                var credit_1255 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1255 = ['Excellent', 'Good'];
                                var pub1255BadStates = ['AZ', 'OH', 'NY', 'MA'];
                                // var housetype_1255 = $('input[name="field_1"]').val();

                                var housetype_1255 = $('input[name="field_1"]:checked').val();

                                if (ltv_1255 <= 0.95 &&
                                    loan_1255 >= 100000 &&
                                    housetype_1255 != "Mobile Home" &&
                                    tier1_credits_1255.indexOf(credit_1255) != -1 &&
                                    pub1255BadStates.indexOf($('input[name="state"]')
                                     .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1255 = document.createElement('iframe');
                                    ifrm1255.setAttribute('scrolling', 'no');
                                    ifrm1255.setAttribute('frameborder', '0');
                                    ifrm1255.setAttribute('width', '1');
                                    ifrm1255.setAttribute('height', '1');
                                    ifrm1255.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=377&e=7&t=' + lead_id);
                                    document.body.appendChild(ifrm1255); // to place at end of document
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1260') {
                                var ltv_1260 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                             
                                var house_1260 = $('select[name="field_2"]').val();
                                var loan_1260 = $('select[name="field_4"]').val();
                                var credit_1260 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1260 = ['Excellent', 'Good'];
                                var pub1260BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1260 = $('input[name="field_1"]:checked').val();

                                if (ltv_1260 <= .95 &&
                                    loan_1260 >= 150000 &&
                                    house_1260 >= 100000 &&
                                    housetype_1260 != "Mobile Home" &&
                                    tier1_credits_1260.indexOf(credit_1260) != -1 &&
                                    pub1260BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var pixel_1260 = '<img src="https://www.facebook.com/tr?id=1062559747516093&ev=Lead&noscript=1" />';
                                    $('body').append(pixel_1260);
                                }
                            }

                            if ($('input[name="publisher_id"]').val() == '1261') {
                                var ltv_1261 = Math.round(($('select[name="field_4"]')
                                    .val() / $('select[name="field_2"]')
                                    .val()) * 100) / 100;
                             
                                var loan_1261 = $('select[name="field_4"]').val();
                                var credit_1261 = $('input[name="field_6"]:checked').val();
                                var tier1_credits_1261 = ['Excellent', 'Good'];
                                var pub1261BadStates = ['AZ', 'OH', 'NY', 'MA'];                             
                                var housetype_1261 = $('input[name="field_1"]:checked').val();

                                if (ltv_1261 <= .95 &&
                                    loan_1261 >= 125000 &&
                                    tier1_credits_1261.indexOf(credit_1261) != -1 &&
                                    pub1261BadStates.indexOf($('input[name="state"]')
                                        .val()) < 0
                                ) {
                                    var lead_id = results.pixel.ref_01;
                                    var ifrm1261 = document.createElement('iframe');
                                    ifrm1261.setAttribute('scrolling', 'no');
                                    ifrm1261.setAttribute('frameborder', '0');
                                    ifrm1261.setAttribute('width', '1');
                                    ifrm1261.setAttribute('height', '1');
                                    ifrm1261.setAttribute('src', 'https://trackqualitylps.com/p.ashx?o=385&e=7&t=' + lead_id + '&r=' + cakereqid);
                                    document.body.appendChild(ifrm1261); // to place at end of document
                                }
                            }
                        }
                    }
                });
                
                getClickWall();
                $('.step.active')
                    .removeClass('active');
                $('.step.step-confirmation')
                    .addClass('active');
                $('.form-top .steps')
                    .empty();

            }
        } else {
            switch (step - 1) {
            case 2:
                step_2.validate();
                break;
            case 3:
                step_3.validate();
                break;
            case 4:
                step_4.validate();
                break;
            case 5:
                step_5.validate();
                break;
            case 6:
                step_6.validate();
                break;
            case 7:
                step_7.validate();
                break;
            case 8:
                step_8.validate();
                break;
            case 9:
                step_9.validate();
                break;
            case 10:
                step_10.validate();
                break;
            case 11:
                step_11.validate();
                break;
            }
            if (!$('.step-form .step.active')
                .hasClass('has-errors')) {
                changeStep(step);
            }
        }
    });

// Tooltip
$(document)
    .on('click', 'a.tooltip', function (e) {
        e.preventDefault();
    });

// Use for the GeoIP2 JavaScript API is to fill state name
var fillState = (function () {
    var getStateName = function (geoipResponse) {
        /*
            most_specific_subdivision returns the smallest available
            subdivision (region) as defined in ISO 3166-2.
        */
        var regionName = geoipResponse.most_specific_subdivision.names.en || '';
        if (regionName) {
            document.getElementById("header-state-text")
                .innerHTML = regionName + ' ';
        }
    };
    var onSuccess = function (geoipResponse) {
        getStateName(geoipResponse);
    };
    /* If we get an error we will do nothing*/
    var onError = function (error) {
        return;
    };
    return function () {
        geoip2.city(onSuccess, onError);
    };
}());

//Fill current date
var dt = new Date();
var month = dt.toDateString()
    .split(' ')[1];
var day = dt.toDateString()
    .split(' ')[2];
var year = dt.toDateString()
    .split(' ')[3];
var currentDate = month + ' ' + day + ', ' + year;
document.getElementById("date")
    .innerHTML = currentDate;

var allowedHashes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];



$(document)
    .ready(function () {

        /*
         * Generate a TrustedForm Certificate
         */
        $('input')
            .on('input', function () {
                this.setAttribute('value', $(this)
                    .val());
            });
        (function () {
            var field = 'TrustedFormToken';
            var provideReferrer = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript';
            tf.async = true;
            tf.src =
                'http' +
                ('https:' == document.location.protocol ? 's' : '') +
                '://api.trustedform.com/trustedform.js?provide_referrer=' +
                escape(provideReferrer) +
                '&field=' +
                escape(field) +
                '&l=' +
                new Date()
                .getTime() +
                Math.random();
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(tf, s);
        })();
        /*
              LEAD ID
           */


        $('input[name=zip_code]')
            .val("");
        $(window)
            .keydown(function (e) {
                if ($('input[name=zip_code]')
                    .index($(e.target)) != -1) {
                    if (
                        // digits, digits in num pad, 'back', 'enter', '.', '-'
                        ($.inArray(e.keyCode, [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 13, 190, 189]) == -1) ||
                        // not allow double dot '.'
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .indexOf(".") != -1) ||
                        // not allow dot '.' at the begining    
                        (e.keyCode == 190 && $(e.target)
                            .val()
                            .length == 0)
                    ) {
                        e.preventDefault();
                    } else {
                        if ($(e.target)
                            .val()
                            .length > 4 && e.keyCode !== 8) {
                            e.preventDefault();
                        }
                    }
                }
            });

        var hashValue = location.hash;
        hashValue = hashValue.replace(/^#/, '');

        if (isInArray(hashValue, allowedHashes)) {
            if (ls('zip')) {
                getCity(ls('zip'));
                setTimeout(function () {
                    showPopUp(false);
                    changeStep(hashValue);
                }, 200);
            }
            setTimeout(function () {
                $('html')
                    .addClass('ready');
            }, 300);
        } else {
            $('html')
                .addClass('ready');
        }

        fillState();
        if (typeof params.zip !== 'undefined') {
            $("input[name='zip_code']")
                .val(params.zip);
            // $('.start-form-submit')
            //     .trigger('click');
        }
        if (
            typeof params.show_confirmation !== 'undefined' &&
            (
                params.show_confirmation === 'true' ||
                params.show_confirmation === '1'
            )
        ) {
            showPopUp();
            $('.popup')
                .removeClass('hide');
            $('.step.active')
                .removeClass('active');
            $('.step.step-confirmation')
                .addClass('active');
            $('.form-top .steps')
                .empty();
            var exit_strategy = true;

            getClickWall(exit_strategy);

        } else if (
            typeof params.optout !== 'undefined' &&
            (
                params.optout === 'true' ||
                params.optout === '1'
            )
        ) {
            $('#opt-out-link')
                .click();
        } else if (
            typeof params.privacy !== 'undefined' &&
            (
                params.privacy === 'true' ||
                params.privacy === '1'
            )
        ) {
            $('#privacy-link')
                .click();
        } else if (
            typeof params.terms !== 'undefined' &&
            (
                params.terms === 'true' ||
                params.terms === '1'
            )
        ) {
            $('#terms-link')
                .click();
        }
    });
